var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-5"},[_vm._v(" Fill in the blanks (dropdowns) in the flow chart below that describes the procedure for "),_c('b',[_vm._v("Elimination lab - Part A1 and A2")]),_vm._v(". See the cartoon depiction of the experimental set up below for any terms you are not familiar with. ")]),_c('p',{staticClass:"mb-5 pl-8"},[_c('v-img',{attrs:{"src":"/img/assignments/UCI_A3_Q10.png","max-width":"457px"}})],1),_c('p',{staticClass:"mb-5"},[_vm._v(" In the reaction tube, place two drops of concentrated "),_c('chemical-latex',{attrs:{"content":"H2SO4\\text{.}"}}),_vm._v(" Add a boiling chip and connect the tube to the gas collection assembly shown in the image. ")],1),_c('p',{staticClass:"mb-5"},[_vm._v(" 1. Fit the "),_c('v-select',{staticClass:"mb-n4",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"dense":"","items":_vm.optionsShuffled,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}}),_vm._v(" with a septum stopper in one end. ")],1),_c('p',{staticClass:"mb-5"},[_vm._v(" 2. Fill the collection tube with "),_c('v-select',{staticClass:"mb-n4",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"dense":"","items":_vm.optionsShuffled,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1),_c('p',{staticClass:"mb-5"},[_vm._v(" 3. Insert the end of the Teflon tubing that is coming out from the top of the "),_c('v-select',{staticClass:"mb-n4",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"dense":"","items":_vm.optionsShuffled,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}}),_vm._v(" into the collection tube all the way up to reach the stopper. ")],1),_c('p',{staticClass:"mb-5"},[_vm._v(" 4. Place your index finger over the "),_c('v-select',{staticClass:"mb-n4",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"dense":"","items":_vm.optionsShuffled,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input4),callback:function ($$v) {_vm.$set(_vm.inputs, "input4", $$v)},expression:"inputs.input4"}}),_vm._v(" end of the tube and invert it (water should remain in the tube). ")],1),_c('p',{staticClass:"mb-5"},[_vm._v(" 5. Place the tube, open end down, into a "),_c('v-select',{staticClass:"mb-n4",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"dense":"","items":_vm.optionsShuffled,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input5),callback:function ($$v) {_vm.$set(_vm.inputs, "input5", $$v)},expression:"inputs.input5"}}),_vm._v(" containing approximately 1/2 water. ")],1),_c('p',{staticClass:"mb-5"},[_vm._v(" 6. "),_c('v-select',{staticClass:"mb-n4",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"dense":"","items":_vm.optionsShuffled,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input6),callback:function ($$v) {_vm.$set(_vm.inputs, "input6", $$v)},expression:"inputs.input6"}}),_vm._v(" your finger (the column of water should remain in the tube). ")],1),_c('p',{staticClass:"mb-5"},[_vm._v(" 7. Connect the other end of the Teflon tubing to the reaction tube as shown in "),_c('v-select',{staticClass:"mb-n4",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"dense":"","items":_vm.optionsShuffled,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input7),callback:function ($$v) {_vm.$set(_vm.inputs, "input7", $$v)},expression:"inputs.input7"}})],1),_c('p',{staticClass:"mb-5"},[_vm._v(" 8. After the assembly is complete, use a syringe to add 0.2 mL of 2-butanol to the reaction tube, and the heat the reaction in a preheated sand bath. Keep heating until no gas bubbles escape from the bottom of the reaction tube. ")]),_c('p',{staticClass:"mb-5"},[_vm._v(" 9. Your collection tube should remain "),_c('v-select',{staticClass:"mb-n4",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"dense":"","items":_vm.optionsShuffled,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input8),callback:function ($$v) {_vm.$set(_vm.inputs, "input8", $$v)},expression:"inputs.input8"}}),_vm._v(" the surface of the water as you remove the Teflon tubing from the tube and beaker. ")],1),_c('p',{staticClass:"mb-5"},[_vm._v(" 10. Label the beaker and tube with your name. Hand the set to your TA for gas chromatography (GC) analysis. Knowing the elution order from your lab procedure, analyze your results to determine the relative amount of the three components. ")]),_c('p',{staticClass:"mb-3"},[_vm._v("A2. Repeat the entire procedure with 1-butanol.")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }