<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Fill in the blanks (dropdowns) in the flow chart below that describes the procedure for
        <b>Elimination lab - Part A1 and A2</b>. See the cartoon depiction of the experimental set
        up below for any terms you are not familiar with.
      </p>

      <p class="mb-5 pl-8">
        <v-img src="/img/assignments/UCI_A3_Q10.png" max-width="457px" />
      </p>

      <p class="mb-5">
        In the reaction tube, place two drops of concentrated
        <chemical-latex content="H2SO4\text{.}" />
        Add a boiling chip and connect the tube to the gas collection assembly shown in the image.
      </p>

      <p class="mb-5">
        1. Fit the
        <v-select
          v-model="inputs.input1"
          style="display: inline-block; width: 12em"
          class="mb-n4"
          dense
          :items="optionsShuffled"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        with a septum stopper in one end.
      </p>

      <p class="mb-5">
        2. Fill the collection tube with
        <v-select
          v-model="inputs.input2"
          style="display: inline-block; width: 12em"
          class="mb-n4"
          dense
          :items="optionsShuffled"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-5">
        3. Insert the end of the Teflon tubing that is coming out from the top of the
        <v-select
          v-model="inputs.input3"
          style="display: inline-block; width: 12em"
          class="mb-n4"
          dense
          :items="optionsShuffled"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        into the collection tube all the way up to reach the stopper.
      </p>

      <p class="mb-5">
        4. Place your index finger over the
        <v-select
          v-model="inputs.input4"
          style="display: inline-block; width: 12em"
          class="mb-n4"
          dense
          :items="optionsShuffled"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        end of the tube and invert it (water should remain in the tube).
      </p>

      <p class="mb-5">
        5. Place the tube, open end down, into a
        <v-select
          v-model="inputs.input5"
          style="display: inline-block; width: 12em"
          class="mb-n4"
          dense
          :items="optionsShuffled"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        containing approximately 1/2 water.
      </p>

      <p class="mb-5">
        6.
        <v-select
          v-model="inputs.input6"
          style="display: inline-block; width: 12em"
          class="mb-n4"
          dense
          :items="optionsShuffled"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        your finger (the column of water should remain in the tube).
      </p>

      <p class="mb-5">
        7. Connect the other end of the Teflon tubing to the reaction tube as shown in
        <v-select
          v-model="inputs.input7"
          style="display: inline-block; width: 12em"
          class="mb-n4"
          dense
          :items="optionsShuffled"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-5">
        8. After the assembly is complete, use a syringe to add 0.2 mL of 2-butanol to the reaction
        tube, and the heat the reaction in a preheated sand bath. Keep heating until no gas bubbles
        escape from the bottom of the reaction tube.
      </p>

      <p class="mb-5">
        9. Your collection tube should remain
        <v-select
          v-model="inputs.input8"
          style="display: inline-block; width: 12em"
          class="mb-n4"
          dense
          :items="optionsShuffled"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        the surface of the water as you remove the Teflon tubing from the tube and beaker.
      </p>

      <p class="mb-5">
        10. Label the beaker and tube with your name. Hand the set to your TA for gas chromatography
        (GC) analysis. Knowing the elution order from your lab procedure, analyze your results to
        determine the relative amount of the three components.
      </p>

      <p class="mb-3">A2. Repeat the entire procedure with 1-butanol.</p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUCI51lbA3_Q10',
  components: {ChemicalLatex, StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
      },
      options: [
        {text: 'collection tube', value: 'collectionTube'},
        {text: 'water', value: 'water'},
        {text: 'septum', value: 'septum'},
        {text: 'open', value: 'open'},
        {text: '250 mL beaker', value: 'beaker'},
        {text: 'remove', value: 'remove'},
        {text: 'cartoon depiction', value: 'figureFour'},
        {text: 'below', value: 'below'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
